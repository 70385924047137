import React, { useEffect, useState, useMemo } from "react";
import { graphql } from "gatsby";
import { useQuery } from "@apollo/react-hooks";
import { get, compact, last } from "lodash";
import PropTypes from "prop-types";
import queryString from "query-string";
import { isMobile } from "react-device-detect";

import Map from "../../components/Map";
import { TextBlock, ContentBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Banner from "../../components/Banner";
import Pages from "../../components/Pages";
import { PureCarousel } from "../../components/PureReactCarousel";
import Collapse from "../../components/Collapse";
import { makeUrl, makeNews, makeArticles, makeHolyPlaces, removePreloader, makeVirtualTourSlides } from "../../helpers";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import CrowdfundingBlock from "../../components/Crowdfunding/CrowdfundingBlock";
import { Share, Weather } from "../../widgets";
import { MetaImage, MetaPublicAccess, MetaName, MetaTelephone, MetaOpeningHours, MetaAddress, MetaGeo } from "../../components/Meta";

import { CROWDFUNDING_PROJECTS, CITY_DATA, HOLY_PLACES } from "../../queries/queries.graphql";

export const query = graphql`
	query citiesDataQuery($slug: String!) {
		hasura {
			...CityContent
			...HolyPlaces
		}
	}
`;

export default function CityPage({ location, data, pageContext }) {
	const slug = last(compact(get(location, "pathname", "").split("/")));

	const { loading: dataLoading, error: dataError, data: pageData } = useQuery(CITY_DATA, {
		variables: {
			slug,
		},
		fetchPolicy: "cache-and-network",
		ssr: false,
	});
	const { data: holyPlacesData } = useQuery(HOLY_PLACES, {
		variables: {
			slug,
		},
		fetchPolicy: "cache-and-network",
		ssr: false,
	});
	// TODO: Need use context
	const { loading: crowdfundingProjectsLoading, error: crowdfundingProjectsError, data: crowdfundingData } = useQuery(CROWDFUNDING_PROJECTS);

	const [targetKey, setTargetKey] = useState("default");
	const [city, setCity] = useState(get(data, "hasura.cities[0]", {}));
	const [crowdfunding, setCrowdfunding] = useState([]);

	function makeData(source = [], url) {
		return source.map(({ title_short, title_full, slug, main_image, main_image_mobile, main_image_preview, monastery }) => {
			const path = compact([get(city, "slug", ""), get(monastery, "slug", ""), slug]).join("/");

			return {
				path: url ? compact([url, slug]).join("/") : path,
				src: main_image_preview && get(main_image_preview, "src", get(main_image, "src")),
				title: title_short || title_full,
				responsive: [
					{
						src: get(main_image_mobile, "src", ""),
						media: "(max-width: 930px)",
					},
				],
			};
		});
	}

	useEffect(() => {
		setTargetKey(get(queryString.parse(location.search), "utm_term", "") || localStorage.getItem("targetKey") || "default");
	}, []);

	useEffect(() => {
		if (!dataError && pageData) {
			setCity(get(pageData, "cities[0]", {}));
		}
		removePreloader(!dataLoading || dataError);
	}, [dataLoading]);

	useEffect(() => {
		if (!crowdfundingProjectsError && crowdfundingData) {
			setCrowdfunding(get(crowdfundingData, "crowdfundingProjects"), []);
		}
	}, [crowdfundingProjectsLoading]);

	useEffect(() => {
		const crowdfundingProjects = get(city, "crowdfunding_projects", "");

		const crowdfundingProjectsIds = crowdfundingProjects
			.filter(e => get(e, "crowdfunding_project.kind", "") === "crowdfunding")
			.map(e => get(e, "crowdfunding_project.id", ""));

		if (!crowdfundingProjectsError) {
			setCrowdfunding(
				get(crowdfundingData, "crowdfundingProjects", []).filter(project =>
					crowdfundingProjectsIds.includes(+project.id),
				),
			);
		}
	}, [crowdfundingProjectsLoading]);

	const url = makeUrl.city({ slug });

	// Normal variable declaration
	const imageSrc = get(city, "main_image_preview.src", get(city, "main_image.src", ""));
	const title_full = get(city, "title_full", "");
	const page_title_full = get(city, "page_title_full", "");
	const page_title_short = get(city, "page_title_short", "");
	const telephone = get(city, "telephone", "");
	const address = get(city, "address", "");
	const openning_hours = get(city, "openning_hours", "");
	const content_blocks = get(city, "content_blocks", []);
	const bannerSlides = [
		{
			title: page_title_full,
			publicURL: get(city, "main_image.src", ""),
			mainImageMobile: get(city, "main_image_mobile.src", ""),
		},
	];
	const museums = makeData(get(city, "museums", []));
	const sights = makeData(get(city, "city_objects", []));
	const news = makeNews(get(city, "cities_news", []).map(item => item.news));
	const virtualTour = makeVirtualTourSlides(get(city, "cities_virtual_tours", []));

	const mediaArticles = makeArticles(get(city, "cities_media_articles", []).map(item => item.media_article));
	const mapItems = [
		city,
		...get(city, "cathedrals", []),
		...get(city, "churches", []),
		...get(city, "temples", []),
		...get(city, "wells", []),
		...get(city, "belltowers", []),
		...get(city, "chapels", []),
		...get(city, "museums", []),
		...get(city, "monasteries", []),
	];
	const coordinates = get(
		get(city, "location_object.features", []).filter(
			feature => get(feature, "geometry.type", "") === "Point",
		),
		"[0].geometry.coordinates",
		[],
	);
	const holyPlaces = makeHolyPlaces(get(holyPlacesData, "v_content_holy_places", []) || get(data, "hasura.v_content_holy_places", []));
	const weather = get(city, "weather", {});

	const banerBlock = useMemo(() => (
		<ContentBlock key={"banner"}>
			<Banner slides={bannerSlides}>
				{isMobile && <Weather weather={weather} />}
			</Banner>
		</ContentBlock>
	), [city, weather]);

	const breadcrumbsBlock = useMemo(() => (
		<ContentBlock key={"breadcrumbs"}>
			<div className="container d-flex justify-content-between">
				<Breadcrumbs currentLabel={title_full}/>
				{!isMobile && <Weather weather={weather}/>}
			</div>
		</ContentBlock>
	), [weather]);

	// const foundedDate = get(city, "founded_date", "");
	// const firstMention = get(city, "first_mention", "");
	// const prevNames = get(city, "prev_names", []).map((item, idx) => <span style={{ display: "block" }} key={idx}>{get(item, "name", "")}</span>);
	// const area = get(city, "area", "");
	// const population = trim(get(city, "population", "")).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");

	// const attributesBlock = useMemo(
	// 	() => (
	// 		<ContentBlock key={"attributes"}>
	// 			<div className="container attributes">
	// 				<div className={"row"}>
	// 					<div className={"col-xs-12 col-sm-6 col-md-6 col-lg-6 attributes__content"}>
	// 						{foundedDate && <div className={"row"}>
	// 							<div className={"col-6"}>Основан:</div>
	// 							<div className={"col-6"}>{`${foundedDate} г.`}</div>
	// 						</div>}
	// 						{firstMention && <div className={"row"}>
	// 							<div className={"col-6"}>Первое упоминание:</div>
	// 							<div className={"col-6"}>{`${firstMention} г.`}</div>
	// 						</div>}
	// 						{prevNames && !!prevNames.lengtn && <div className={"row"}>
	// 							<div className={"col-6"}>Прежние названия:</div>
	// 							<div className={"col-6"}>{prevNames}</div>
	// 						</div>}
	// 						{area && <div className={"row"}>
	// 							<div className={"col-6"}>Площадь:</div>
	// 							<div className={"col-6"}>{area} км<sup>2</sup></div>
	// 						</div>}
	// 						{population && <div className={"row"}>
	// 							<div className={"col-6"}>Население:</div>
	// 							<div className={"col-6"}>{`${population} чел.`}</div>
	// 						</div>}
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</ContentBlock>
	// 	),
	// 	[city],
	// );

	const historyBlock = useMemo(
		() => (
			<ContentBlock key={"content_blocks"} mount={!!content_blocks.length}>
				<div className={"container"}>
					<Collapse contentBlocks={content_blocks && content_blocks}>
						<Contents items={content_blocks} />
					</Collapse>
				</div>
			</ContentBlock>
		),
		[city],
	);
	const newsBlock = useMemo(
		() => (
			<ContentBlock key={"news"} mount={news && !!news.length}>
				<div className={"container"}>
					<TextBlock title={"Новости"} list={"Смотреть всё"} listLink={makeUrl.contentNews(pageContext)} allowBtn={news && news.length > 5}>
						<PureCarousel
							type={"SlideNews"}
							items={news}
							mediaSize={["100%", 400]}
							slideRatio={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							slideRatioMobile={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							params={{
								slidesPerView: 4,
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 768,
										slidesPerView: 2,
									},
									{
										width: 950,
										slidesPerView: 3,
									},
								],
								btnStyle: {
									top: "40%",
								},
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[city],
	);
	const articlesBlock = useMemo(
		() => (
			<ContentBlock key={"articles"} mount={mediaArticles && !!mediaArticles.length}>
				<div className={"container"}>
					<TextBlock title={"Статьи"} list={"Смотреть всё"} listLink={makeUrl.mediaArticles(pageContext)} allowBtn={mediaArticles && mediaArticles.length > 5}>
						<PureCarousel
							type={"SlideNews"}
							items={mediaArticles}
							mediaSize={["100%", 400]}
							slideRatio={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 5.5,
							}}
							slideRatioMobile={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							params={{
								slidesPerView: 4,
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 576,
										slidesPerView: 2,
									},
									{
										width: 768,
										slidesPerView: 3,
									},
									{
										width: 1136,
										slidesPerView: 4,
									},
								],
								btnStyle: {
									top: "40%",
								},
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[city],
	);
	const buildingsBlock = useMemo(
		() => (
			<ContentBlock key={"holyPlaces"} mount={holyPlaces && !!holyPlaces.length}>
				<div className={"container"}>
					<TextBlock title={"Святые места"} list={"Смотреть всё"} listLink={makeUrl.holyPlaces(pageContext)} allowBtn={holyPlaces && holyPlaces.length > 5}>
						<PureCarousel
							type={"SlideLinks"}
							items={holyPlaces}
							slideRatio={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							slideRatioMobile={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							params={{
								slidesPerView: 4,
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 576,
										slidesPerView: 2,
									},
									{
										width: 768,
										slidesPerView: 3,
									},
									{
										width: 1136,
										slidesPerView: 4,
									},
								],
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[holyPlaces],
	);
	const crowdfundingBlock = useMemo(
		() => (
			<ContentBlock key={"crowdfunding"} mount={crowdfunding && !!crowdfunding.length}>
				<div className={"container"}>
					<TextBlock title={"Поддержать проект"}>
						<CrowdfundingBlock data={crowdfunding} />
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[crowdfunding],
	);
	const museumsBlock = useMemo(
		() => (
			<ContentBlock key={"museums"} mount={museums && !!museums.length}>
				<div className={"container"}>
					<TextBlock title={"Музеи"} list={"Смотреть всё"} listLink={makeUrl.museums(pageContext)} allowBtn={museums && museums.length > 5}>
						<PureCarousel
							type={"SlideLinks"}
							items={museums}
							name={"museums"}
							slideRatio={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							slideRatioMobile={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							params={{
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 576,
										slidesPerView: 2,
									},
									{
										width: 768,
										slidesPerView: 3,
									},
									{
										width: 1136,
										slidesPerView: 4,
									},
								],
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[city],
	);
	const cityObjectsBlock = useMemo(
		() => (
			<ContentBlock key={"sights"} mount={sights && !!sights.length}>
				<div className={"container"}>
					<TextBlock title={"Достопримечательности"}
						list={"Смотреть всё"}
						listLink={makeUrl.cityObjects(pageContext)}
						allowBtn={sights && sights.length > 5}>
						<PureCarousel
							type={"SlideLinks"}
							items={sights}
							slideRatio={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
							params={{
								slidesPerView: 4,
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 576,
										slidesPerView: 2,
									},
									{
										width: 768,
										slidesPerView: 3,
									},
									{
										width: 1136,
										slidesPerView: 4,
									},
								],
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[city],
	);
	const mapBlock = useMemo(
		() => (
			<ContentBlock key={"mapItems"} mount={mapItems && !!mapItems.length}>
				<Map items={mapItems} useSlug={true} />
			</ContentBlock>
		),
		[city],
	);
	const videoBlock = useMemo(
		() => (
			<ContentBlock key={"videoBlock"} mount={title_full === "Арзамас"}>
				<div className={"container"}>
					<TextBlock title={"Видео Арзамас"} desc="" />
				</div>
				<div className={"container"}>
					{/* FIXME: Need to Implement and use Video component */}
					<video controls width={"100%"} className={"pb-5"}>
						<source
							src={"https://storage.yandexcloud.net/serafim-3d/arzamas_fly.mp4"}
							type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
						/>
						Тег video не поддерживается вашим браузером.
					</video>
				</div>
			</ContentBlock>
		),
		[city],
	);
	const virtualToursBlock = useMemo(
		() => (
			<ContentBlock key={"virtual_tours"} mount={virtualTour && !!virtualTour.length}>
				<div className={"container"} >
					<TextBlock title={"Виртуальные экскурсии"} id={"Virtual-Tours"}>
						<PureCarousel
							type={"SlideLinks"}
							items={virtualTour}
							visibleSlides={2}
							slideRatio={{
								naturalSlideWidth: 2,
								naturalSlideHeight: 1,
							}}
							slideRatioMobile={{
								naturalSlideWidth: 1,
								naturalSlideHeight: 0.8,
							}}
							params={{
								breakpoints: [
									{
										width: 0,
										slidesPerView: 1,
									},
									{
										width: 576,
										slidesPerView: 2,
									},
								],
							}}
						/>
					</TextBlock>
				</div>
			</ContentBlock>
		),
		[city],
	);

	const blocksList = {
		banerBlock,
		breadcrumbsBlock,
		historyBlock,
		newsBlock,
		articlesBlock,
		buildingsBlock,
		crowdfundingBlock,
		museumsBlock,
		cityObjectsBlock,
		mapBlock,
		videoBlock,
		virtualToursBlock,
	};

	const target = {
		"default": [
			"banerBlock",
			"breadcrumbsBlock",
			"historyBlock",
			"buildingsBlock",
			"museumsBlock",
			"cityObjectsBlock",
			"newsBlock",
			"articlesBlock",
			"videoBlock",
			"crowdfundingBlock",
			"virtualToursBlock",
			"mapBlock",
		],
		"trudnik": [
			"banerBlock",
			"breadcrumbsBlock",
			"historyBlock",
			"newsBlock",
			"articlesBlock",
			"buildingsBlock",
			"cityObjectsBlock",
			"museumsBlock",
			"crowdfundingBlock",
			"virtualToursBlock",
			"videoBlock",
			"mapBlock",
		],
		"tourist": [
			"banerBlock",
			"breadcrumbsBlock",
			"historyBlock",
			"cityObjectsBlock",
			"virtualToursBlock",
			"museumsBlock",
			"newsBlock",
			"articlesBlock",
			"buildingsBlock",
			"videoBlock",
			"crowdfundingBlock",
			"mapBlock",
		],
		"palomnik": [
			"banerBlock",
			"breadcrumbsBlock",
			"historyBlock",
			"buildingsBlock",
			"crowdfundingBlock",
			"videoBlock",
			"virtualToursBlock",
			"cityObjectsBlock",
			"museumsBlock",
			"newsBlock",
			"articlesBlock",
			"mapBlock",
		],
	};

	return (
		<Pages entity={city} url={url}>
			<div itemScope itemType="https://schema.org/City">
				<MetaPublicAccess content="true" />
				<MetaGeo content={coordinates} />
				<MetaImage content={imageSrc} />
				<MetaName content={title_full} />
				<MetaTelephone content={telephone} />
				<MetaOpeningHours content={openning_hours} />
				<MetaAddress content={address} />
				{target[targetKey].map(item => blocksList[item])}
				<div className={"container"}>
					<Share
						url={url}
						pageTitleShort={page_title_short}
						pageTitleFull={page_title_full}
						imageSrc={imageSrc}
					/>
				</div>
			</div>
		</Pages>
	);
}

CityPage.propTypes = {
	location: PropTypes.object,
	data: PropTypes.object,
	pageContext: PropTypes.object,
	pageData: PropTypes.object,
	crowdfundingData: PropTypes.object,
	holyPlacesData: PropTypes.object,
};

CityPage.defaultProps = {
	location: {},
	data: {},
	pageContext: {},
	pageData: {},
	crowdfundingData: {},
};
